import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, TextField, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";

import { useStore } from "@store/store";
import Footer from "@components/footer";
import Header from "@components/header";
import Loader from "@components/loader";
import { schema } from "@utils/schemas";
import validationSchema from "@utils/validationSchemas";
import { routes } from "@utils/constant";
import {
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	FETCH_CHILD_ACCOUNTS,
	FETCH_CHILD_ACCOUNTS_SUCCESS,
	FETCH_CHILD_ACCOUNTS_FAILURE
} from "@utils/actionTypes";
import { postApi } from "@services/axios";
import { loginStyle } from "./style";
import { setEmail, setLogin } from "@utils/commonFunctions";
import { getToken, getAccess } from "@utils/commonFunctions";
import {getUserData, setAccess, setTimeZone, setChildId} from "@utils/commonFunctions";
import { getApi } from "@services/axios";
import common from "../../reducers/common";

function LoginView() {
	const [err, setErr] = useState("");
	const history = useHistory();
	const [state, dispatch] = useStore();
	const classes = loginStyle();

	useEffect(() => {
    if (getToken() && getAccess()) {
      history.push(routes.dashboard);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

	const formik = useFormik({
		initialValues: schema.loginSchema,
		validationSchema: validationSchema.loginValidationSchema,
		onSubmit: (values) => {
			dispatch({ type: LOGIN });
			postApi("auth/login", values)
				.then((response) => {
					dispatch({ type: LOGIN_SUCCESS, payload: response.data });
					if (getUserData()?.connectedCustomer) {
						//Set default time zone of first customer Id
						setTimeZone(getUserData()?.customerInfo[0]?.cities?.timezone);
					} else {
						setTimeZone(getUserData()?.timezone);
					}

					//Get Child Accounts
					dispatch({ type: FETCH_CHILD_ACCOUNTS });
					getApi("childAccounts", null, false)
					.then((responseChildData) => {
						dispatch({
							type: FETCH_CHILD_ACCOUNTS_SUCCESS,
							payload: responseChildData.data,
						});

						let childAccountArr = [];
						responseChildData?.data?.data?.rows.map(item => {
							childAccountArr.push(item.id);
						})

						setChildId(childAccountArr);
						setLogin(true);
						setEmail(values.email);
						setAccess();
						history.push(routes.dashboard);

					})
					.catch((error) => {
						dispatch({
							type: FETCH_CHILD_ACCOUNTS_FAILURE,
							payload: error.response.data,
						});
					});

				})
				.catch((error) => {
					dispatch({ type: LOGIN_FAILURE, payload: error.response.data });
					if (error.response.status === 400) {
						setErr(error.response?.data?.message);
					}
				});
		},
	});

	return (
		<>
			<Header />
			<div className={classes.commonBannerWrapper}>
				<Loader loading={state.login.loading || state.common.loadingChildAccounts} />
				<div className="commonBanner">
					<div className="white-box-wrapper">
						<div className="white-box">
							<div className="alert">
								{err && <Alert severity="error">{err}</Alert>}
							</div>
							<div className="title-wrapper">
								<Typography variant="h1">Customer Login</Typography>
							</div>
							<form
								noValidate
								autoComplete="off"
								className="custom-form"
								onSubmit={formik.handleSubmit}
							>
								<div className="form-row">
									<div className="form-gourp">
										<TextField
											id="email"
											name="email"
											label="Email"
											placeholder="Email"
											variant="outlined"
											onChange={formik.handleChange}
											value={formik.values.email}
											error={
												formik.touched.email && Boolean(formik.errors.email)
											}
											helperText={formik.touched.email && formik.errors.email}
										/>
									</div>
								</div>
								<div className="form-row">
									<div className="form-gourp">
										<TextField
											id="password"
											name="password"
											label="Password"
											type="password"
											placeholder="Password"
											variant="outlined"
											onChange={formik.handleChange}
											value={formik.values.password}
											autoComplete="on"
											error={
												formik.touched.password &&
												Boolean(formik.errors.password)
											}
											helperText={
												formik.touched.password && formik.errors.password
											}
										/>
									</div>
								</div>
								<div className="form-row btn-wrapper">
									<Button
										type="submit"
										className="orange-btn"
										variant="contained"
										color="primary"
										disableElevation
									>
										Login
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default LoginView;
