import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Typography } from "@material-ui/core";

import Loader from "@components/loader";
import { useStore } from "@store/store";
import { getApi } from "@services/axios";
import { EditBlockStyle } from "./style";
import { getUserData } from "@utils/commonFunctions";

function EditBlock(props) {
  const classes = EditBlockStyle();
  const [state] = useStore();
  const { id } = useParams();

  const handleDownload = () => {
    getApi(`jobs/${id}/downloadJobRunsheetPDF`)
      .then((response) => {
        window.open(response.data.data, "_blank");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className={classes.EditBlockWrapper}>
      <Loader
        loading={
          props.loadingJobs ||
          state.job?.loadingJobConsignment ||
          state.job?.loadingAirWayBill
        }
      />
      <div className="white-card edit-block">
        <div className="edit-block-header">
          <div className="inner-heading">
            <p>Job Number</p>
            <span>{props.jobsData?.id || "-"}</span>
          </div>
        </div>
        {props.jobsData?.description && (
          <div className="edit-block-content">
            <Typography variant="h6">Description:</Typography>
            <p>{props.jobsData?.description}</p>
          </div>
        )}
        <div className="edit-block-list">
          <ul>
            <li>
              <Typography variant="h6">Job type</Typography>
              <div className="value-block">
                <p>{props.jobsData?.jobTypes?.name || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Cargo type</Typography>
              <div className="value-block">
                <p>{props.jobsData?.cargoTypes?.name || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Requester</Typography>
              <div className="value-block">
                <p>{props.jobsData?.requesterName || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Email</Typography>
              <div className="value-block">
                <p>{props.jobsData?.email || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Weight</Typography>
              <div className="value-block">
                <p>
                  {props.jobsData?.weight ? props.jobsData?.weight + " Kg" : 0}
                </p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Quantity</Typography>
              <div className="value-block">
                <p>{props.jobsData?.quantity ? props.jobsData?.quantity : 0}</p>
              </div>
            </li>
            {/* https://wymap.atlassian.net/browse/MAPTRAK-1034 Added weight and qty loaded */}
            <li>
              <Typography variant="h6">Loaded Weight</Typography>
              <div className="value-block">
                <p>
                  {props.jobsData?.weightLoaded
                    ? props.jobsData?.weightLoaded + " Kg"
                    : 0}
                </p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Loaded Quantity </Typography>
              <div className="value-block">
                <p>
                  {props.jobsData?.quantityLoaded
                    ? props.jobsData?.quantityLoaded
                    : 0}
                </p>
              </div>
            </li>
            <li>
              <Typography variant="h6">City</Typography>
              <div className="value-block">
                <p>
                  {props?.jobsData?.cities ? props?.jobsData?.cities?.name : "-"}
                </p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Pick up</Typography>
              <div className="value-block">
                <p>{props?.jobsData?.pickUpLocation || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Drop off</Typography>
              <div className="value-block">
                <p>{props?.jobsData?.dropOffLocation || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Driver</Typography>
              <div className="value-block">
                {props?.jobsData?.drivers ? props?.jobsData?.drivers?.name : "-"}
              </div>
            </li>
            <li>
              <Typography variant="h6">Truck Rego</Typography>
              <div className="value-block">
                {props?.jobsData?.trucks ? props?.jobsData?.trucks?.rego : "-"}
              </div>
            </li>
            <li>
              <Typography variant="h6">Truck Type</Typography>
              <div className="value-block">
                {props?.jobsData?.trucks ? props?.jobsData?.trucks?.truckType?.name : "-"}
              </div>
            </li>
            <li>
              <Typography variant="h6">CTO</Typography>
              <div className="value-block">
                {props.jobsData?.ctos?.name || "-"}
              </div>
            </li>
            <li>
              <Typography variant="h6">Status</Typography>
              <div className="value-block">
                <p>{props.jobsData?.jobStatuses?.name || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Duration</Typography>
              <div className="value-block">
                <label
                  className={
                    parseFloat(props.jobsData?.totalDuration / 60).toFixed(2) >
                    2
                      ? "chart-info-dot yellow-text label-text"
                      : "label-text"
                  }
                >
                  {props.jobsData?.totalDuration
                    ? parseFloat(props.jobsData?.totalDuration / 60).toFixed(2)
                    : "-"}
                </label>
              </div>
            </li>
            {getUserData()?.connectedCustomer && <li>
              <Typography variant="h6">Customer</Typography>
              <div className="value-block">
                <p>{props.jobsData?.customers?.name || "-"}</p>
              </div>
            </li>
            }
            {["Review Completed", "Completed"].includes(
                props.jobsData?.jobStatuses?.name
            ) && (
              <li>
                <Typography variant="h6">Run sheet</Typography>
                <div className="value-block">
                  <span onClick={handleDownload}>Download</span>
                </div>
              </li>
            )}
            {/* <li>
              <Typography variant="h6">Run sheet</Typography>
              <div className="value-block">
                <span onClick={handleDownload}>Download</span>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default EditBlock;
