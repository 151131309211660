import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import { useFormik } from "formik";

import { useStore } from "@store/store";
import DeletePopup from "@components/deletePopup";
import deleteIcon from "@assets/images/delete.svg";
import editIcon from "@assets/images/edit.svg";
import validationSchema from "@utils/validationSchemas";
import { routes } from "@utils/constant";
import Loader from "@components/loader";
import {
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_JOBTYPES,
  GET_JOBTYPES_SUCCESS,
  GET_JOBTYPES_FAILURE,
  GET_CARGO_TYPE,
  GET_CARGO_TYPE_SUCCESS,
  GET_CARGO_TYPE_FAILURE,
  GET_CTOS,
  GET_CTOS_SUCCESS,
  GET_CTOS_FAILURE,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,
  EDIT_ONLINE_REQUEST,
  EDIT_ONLINE_REQUEST_SUCCESS,
  EDIT_ONLINE_REQUEST_FAILURE,
  DELETE_ONLINE_REQUEST,
  DELETE_ONLINE_REQUEST_SUCCESS,
  DELETE_ONLINE_REQUEST_FAILURE,
  GET_CUSTOMER_DIVISION,
  GET_CUSTOMER_DIVISION_SUCCESS,
  GET_CUSTOMER_DIVISION_FAILURE
} from "@utils/actionTypes";
import { getApi, putApi, deleteApi } from "@services/axios";
import Popup from "@views/online-request/request-form/popup";
import { EditBlockStyle } from "./style";
import { getUserData } from "@utils/commonFunctions";
import { getCityIds } from "../../../utils/commonFunctions";

function EditBlock(props) {
  const classes = EditBlockStyle();
  const [state, dispatch] = useStore();
  const [open, setOpen] = useState(false);
  const [openDeletePopup, setDeletePopup] = useState(false);
  const [error, setError] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [address, setAddress] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const checkCustomerDivisionHaveManyCustomer = getUserData()?.customerInfo?.length > 1;

  const handleClickOpen = () => {
    setOpen(true);
    setEdit(true);
    formik.handleReset();
  };
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    formik.handleReset();
    setError("");
  };

  const handleDelete = () => {
    setDeletePopup(false);
    dispatch({ type: DELETE_ONLINE_REQUEST });
    deleteApi(`/onlineRequests/${id}`)
      .then(() => {
        dispatch({
          type: DELETE_ONLINE_REQUEST_SUCCESS,
        });
        history.push(routes.onlineRequest);
        toast.success("Online Request Deleted Successfully");
      })
      .catch((error) => {
        dispatch({ type: DELETE_ONLINE_REQUEST_FAILURE });
        setError(error.response?.data?.message);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleDeletePopup = () => {
    setDeletePopup(true);
  };

  const handleChange = (address, type) => {
    type === "pickUpLocation"
      ? formik.setFieldValue("pickUpLocation", address)
      : formik.setFieldValue("dropOffLocation", address);
  };

  // Handle lat/long and address of google place api
  const handleSelect = (address, type) => {
    type === "pickUpLocation"
      ? formik.setFieldValue("pickUpLocation", address)
      : formik.setFieldValue("dropOffLocation", address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        return type === "pickUpLocation"
          ? (formik.setFieldValue("pickUpLatitude", latLng.lat),
            formik.setFieldValue("pickUpLongitude", latLng.lng))
          : (formik.setFieldValue("dropOffLatitude", latLng.lat),
            formik.setFieldValue("dropOffLongitude", latLng.lng));
      })
      .catch((error) => console.error("Error", error));
  };

  const init = props.onlineData;
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: (getUserData()?.connectedCustomer && checkCustomerDivisionHaveManyCustomer)
        ? validationSchema.makeRequestValidationSchemaForConnectedCustomer
        : validationSchema.makeRequestValidationSchema,
    initialValues: {
      referenceNo: init?.referenceNo || "",
      jobTypeId: init?.jobTypes?.id || "",
      jobTypeLabel: init?.jobTypes?.name || "",
      requesterName: init?.requesterName || "",
      email: init?.email || "",
      // cc: init?.cc?.length ? init.cc?.split(",") : "",
      cityId: init?.cities?.id || "",
      cargoTypeId: init?.cargoTypes?.id || "",
      quantity: init?.quantity || 0,
      radioType: init?.ctoType === "1" ? "pickUp" : "dropOff",
      ctoId: init?.ctos?.id || "",
      pickUpLocation:
        init?.jobTypes?.name === "Import" ||
        (init?.jobTypes?.name === "Empty" && init?.ctoType === "1")
          ? ""
          : init?.pickUpLocation,
      pickUpLatitude:
        init?.jobTypes?.name === "Import" ||
        (init?.jobTypes?.name === "Empty" && init?.ctoType === "1")
          ? ""
          : init?.pickUpLatitude,
      pickUpLongitude:
        init?.jobTypes?.name === "Import" ||
        (init?.jobTypes?.name === "Empty" && init?.ctoType === "1")
          ? ""
          : init?.pickUpLongitude,
      pickUpCustomerAddressId: init?.pickUpCustomerAddressId
        ? init?.pickUpCustomerAddressId
        : "",
      dropOffLocation:
        init?.jobTypes?.name === "Export" ||
        (init?.jobTypes?.name === "Empty" && init?.ctoType === "2")
          ? ""
          : init?.dropOffLocation,
      dropOffLatitude:
        init?.jobTypes?.name === "Export" ||
        (init?.jobTypes?.name === "Empty" && init?.ctoType === "2")
          ? ""
          : init?.dropOffLatitude,
      dropOffLongitude:
        init?.jobTypes?.name === "Export" ||
        (init?.jobTypes?.name === "Empty" && init?.ctoType === "2")
          ? ""
          : init?.dropOffLongitude,
      dropOffCustomerAddressId: init?.dropOffCustomerAddressId
        ? init?.dropOffCustomerAddressId
        : "",
      description: init?.description || "",
      customerDivisionId: (getUserData()?.connectedCustomer && checkCustomerDivisionHaveManyCustomer) ? init?.customers?.id : "",
    },

    onSubmit: (value) => {
      let data = {
        referenceNo: value.referenceNo,
        jobTypeId: value.jobTypeId,
        cityId: value.cityId,
        pickUpLocation: value.pickUpLocation
          ? value.pickUpLocation
          : (value.ctoId &&
              value.jobTypeLabel === "Empty" &&
              value.radioType === "pickUp") ||
            value.jobTypeLabel === "Import"
          ? value.ctoData?.location
            ? value.ctoData?.location
            : init?.pickUpLocation
          : "",
        pickUpLatitude: value.pickUpLatitude
          ? value.pickUpLatitude
          : value.ctoId
          ? value.ctoData?.latitude
            ? value.ctoData?.latitude
            : init?.pickUpLatitude
          : "",
        pickUpLongitude: value.pickUpLongitude
          ? value.pickUpLongitude
          : value.ctoId
          ? value.ctoData?.longitude
            ? value.ctoData?.longitude
            : init?.pickUpLongitude
          : "",
        dropOffLocation: value.dropOffLocation
          ? value.dropOffLocation
          : (value.ctoId &&
              value.jobTypeLabel === "Empty" &&
              value.radioType === "dropOff") ||
            value.jobTypeLabel === "Export"
          ? value.ctoData?.location
            ? value.ctoData?.location
            : init?.dropOffLocation
          : "",
        dropOffLatitude: value.dropOffLatitude
          ? value.dropOffLatitude
          : value.ctoId
          ? value.ctoData?.latitude
            ? value.ctoData?.latitude
            : init?.dropOffLatitude
          : "",
        dropOffLongitude: value.dropOffLongitude
          ? value.dropOffLongitude
          : value.ctoId
          ? value.ctoData?.longitude
            ? value.ctoData?.longitude
            : init?.dropOffLongitude
          : "",
      };
      data = {
        ...data,
        ...(!!value.requesterName
          ? { requesterName: value.requesterName }
          : {}),
        ...(!!value.email ? { email: value.email } : {}),
        ...(!!value.cc.length ? { cc: value.cc?.toString() } : {}),
        ...(!!value.cargoTypeId ? { cargoTypeId: value.cargoTypeId } : {}),
        ...(!!value.quantity && value.jobTypeLabel === "Empty"
          ? { quantity: value.quantity }
          : {}),
        ...(!!value.radioType
          ? { ctoType: value.radioType === "pickUp" ? "1" : "2" }
          : {}),
        ...(!!value.ctoId ? { ctoId: value.ctoId } : {}),
        ...(!!value.pickUpCustomerAddressId
          ? { pickUpCustomerAddressId: value.pickUpCustomerAddressId }
          : {}),
        ...(!!value.dropOffCustomerAddressId
          ? { dropOffCustomerAddressId: value.dropOffCustomerAddressId }
          : {}),
        ...(!!value.description ? { description: value.description } : {}),
        ...(!!value.customerDivisionId ? { customerDivisionId: value.customerDivisionId } : {}),
      };
      if (!data.pickUpLocation) data.pickUpLocation = value.ctoData?.location;
      if (!data.pickUpLatitude) data.pickUpLatitude = value.ctoData?.latitude;
      if (!data.pickUpLongitude)
        data.pickUpLongitude = value.ctoData?.longitude;
      if (!data.dropOffLocation) data.dropOffLocation = value.ctoData?.location;
      if (!data.dropOffLatitude) data.dropOffLatitude = value.ctoData?.latitude;
      if (!data.dropOffLongitude)
        data.dropOffLongitude = value.ctoData?.longitude;
      dispatch({ type: EDIT_ONLINE_REQUEST });
      putApi(`onlineRequests/${id}`, data)
        .then((response) => {
          dispatch({
            type: EDIT_ONLINE_REQUEST_SUCCESS,
            payload: response.data.data,
          });
          props.getOnlineRequestById();
          handleClose();
          toast.success("Online Request Updated SuccessFully");
        })
        .catch((error) => {
          dispatch({ type: EDIT_ONLINE_REQUEST_FAILURE, payload: error });
          setError(error.response?.data?.message);
        });
    },
  });

  useEffect(() => {
    if (open)
      formik.setFieldValue("cc", init?.cc?.length ? init.cc?.split(",") : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChip = (e) => {
    if (formik.values.cc) {
      formik.setFieldValue("cc", [...formik.values.cc, e[e?.length - 1]]);
    } else {
      formik.setFieldValue("cc", e);
    }
  };
  // API calling when create job popup opens
  useEffect(() => {
    if (open) {
      dispatch({ type: GET_ADDRESS });
      getApi("master/addresses", null, false)
        .then((response) => {
          dispatch({
            type: GET_ADDRESS_SUCCESS,
            payload: response.data.data,
          });
          //Filter list of Address location base on Customer Division in Connected Customer User login
          if (getUserData()?.connectedCustomer && checkCustomerDivisionHaveManyCustomer) {
            let filteredAddress = response.data.data?.filter((item) => {
              return item?.customerId === formik.values.customerDivisionId
            });
            setAddress(filteredAddress);
          } else {
            setAddress(response.data.data);
          }
        })
        .catch((error) => {
          dispatch({ type: GET_ADDRESS_FAILURE, payload: error });
        });

      dispatch({ type: GET_CITIES });
      getApi("master/cities", null, false)
        .then((response) => {
          dispatch({
            type: GET_CITIES_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_CITIES_FAILURE, payload: error });
        });

      dispatch({ type: GET_JOBTYPES });
      getApi("master/jobTypes", null, false)
        .then((response) => {
          dispatch({
            type: GET_JOBTYPES_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_JOBTYPES_FAILURE, payload: error });
        });

      dispatch({ type: GET_CARGO_TYPE });
      getApi("master/cargoTypes", null, false)
        .then((response) => {
          dispatch({
            type: GET_CARGO_TYPE_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_CARGO_TYPE_FAILURE, payload: error });
        });

      const cityIds = getCityIds();
      const params = {
        cityId: cityIds,
      };
      dispatch({ type: GET_CTOS });
      getApi("master/ctos", { params }, false)
          .then((response) => {
            dispatch({
              type: GET_CTOS_SUCCESS,
              payload: response.data.data,
            });
          })
          .catch((error) => {
            dispatch({ type: GET_CTOS_FAILURE, payload: error });
          });

      if (getUserData()?.connectedCustomer && checkCustomerDivisionHaveManyCustomer) {
        dispatch({ type: GET_CUSTOMER_DIVISION });
        getApi("users/getDivisionCustomer", null, false)
          .then((response) => {
            dispatch({
              type: GET_CUSTOMER_DIVISION_SUCCESS,
              payload: response.data.data,
            });
          })
          .catch((error) => {
            dispatch({ type: GET_CUSTOMER_DIVISION_FAILURE, payload: error });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  useEffect(() => {
    if (formik.values.jobTypeLabel === "Empty") {
      if (formik.values.radioType === "pickUp") {
        formik.setFieldValue("pickUpLocation", "");
        formik.setFieldValue("pickUpLongitude", "");
        formik.setFieldValue("pickUpLatitude", "");
        formik.setFieldValue("pickUpCustomerAddressId", "");
      }
      if (formik.values.radioType === "dropOff") {
        formik.setFieldValue("dropOffLocation", "");
        formik.setFieldValue("dropOffLongitude", "");
        formik.setFieldValue("dropOffLatitude", "");
        formik.setFieldValue("dropOffCustomerAddressId", "");
      }
    }
    // eslint-disable-next-line
  }, [formik.values.radioType]);
  useEffect(() => {
    formik.setFieldValue("radioType", "");
    if (
      ["Metro", "Airside", "Ad-Hoc", "Transfer", "Interstate"].includes(
        formik.values.jobTypeLabel
      )
    ) {
      formik.setFieldValue("cargoTypeId", "");
    } else {
      formik.setFieldValue(
        "cargoTypeId",
        init?.cargoTypes?.id ? init?.cargoTypes?.id : ""
      );
    }
    if (formik.values.jobTypeLabel === "Empty") {
      formik.setFieldValue(
        "cargoTypeId",
        state?.common?.cargoTypeData?.find((item) => item.name === "ULD").id
      );
    }

    formik.setFieldValue("ctoId", "");
    if (
      ["Export", "Metro", "Ad-Hoc", "Empty"].includes(
        formik.values.jobTypeLabel
      ) ||
      (["Export", "Metro", "Ad-Hoc"].includes(init?.jobTypes?.name) &&
        !init?.ctos?.id)
    ) {
      formik.setFieldValue("dropOffCustomerAddressId", "");
      formik.setFieldValue("dropOffLocation", "");
      formik.setFieldValue("dropOffLongitude", "");
      formik.setFieldValue("dropOffLatitude", "");
    }
    if (
      ["Import", "Metro", "Ad-Hoc", "Empty"].includes(
        formik.values.jobTypeLabel
      ) ||
      (["Import", "Metro", "Ad-Hoc"].includes(init?.jobTypes?.name) &&
        !init?.ctos?.id)
    ) {
      formik.setFieldValue("pickUpCustomerAddressId", "");
      formik.setFieldValue("pickUpLocation", "");
      formik.setFieldValue("pickUpLongitude", "");
      formik.setFieldValue("pickUpLatitude", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.jobTypeLabel]);

  return (
    <div className={classes.EditBlockWrapper}>
      <Loader
          loading={
              state.onlineRequest?.deletingOnlineRequest ||
              state.onlineRequest?.loadingOnlineRequestConsignment ||
              state.onlineRequest?.loadingAirWayBill
          }
      />
      <div className="white-card edit-block">
        <div className="edit-block-header">
          <div className="inner-heading">
            <p>Online Request Id</p>
            <span>{init?.id ? init?.id : "-"}</span>
          </div>
          {["Created", "Acknowledged"].includes(
            init?.onlineRequestStatuses?.name
          ) && (
            <div className="link-block">
              <span title="Delete" onClick={handleDeletePopup}>
                <em>
                  <img src={deleteIcon} alt="Delete" />
                </em>
                Delete
              </span>
              <span title="Edit" onClick={handleClickOpen}>
                <em>
                  <img src={editIcon} alt="Edit" />
                </em>
                Edit
              </span>
            </div>
          )}
        </div>
        {init?.description && (
          <div className="edit-block-content">
            <Typography variant="h6">Description:</Typography>
            <p>{init?.description}</p>
          </div>
        )}

        <div className="edit-block-list">
          <ul>
            <li>
              <Typography variant="h6">Job type</Typography>
              <div className="value-block">
                <p>{init?.jobTypes?.name || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Cargo type</Typography>
              <div className="value-block">
                <p>{init?.cargoTypes?.name || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Requester</Typography>
              <div className="value-block">
                <p>{init?.requesterName || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Email</Typography>
              <div className="value-block">
                <p>{init?.email || "-"}</p>
              </div>
            </li>
            {/* https://wymap.atlassian.net/browse/MAPTRAK-1034 Added weight and qty loaded/total */}
            <li>
              <Typography variant="h6">Total Weight</Typography>
              <div className="value-block">
                <p>{init?.weight ? init?.weight + " Kg" : 0}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Total Quantity</Typography>
              <div className="value-block">
                <p>{init?.quantity || 0}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Loaded Weight</Typography>
              <div className="value-block">
                <p>{init?.weightLoaded ? init?.weightLoaded + " Kg" : 0}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Loaded Quantity </Typography>
              <div className="value-block">
                <p>{init?.quantityLoaded || 0}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">City</Typography>
              <div className="value-block">
                <p>{init?.cities?.name || "-"}</p>
              </div>
            </li>

            <li>
              <Typography variant="h6">Pick up</Typography>
              <div className="value-block">
                <p>{init?.pickUpLocation || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">Drop off</Typography>
              <div className="value-block">
                <p>{init?.dropOffLocation || "-"}</p>
              </div>
            </li>
            <li>
              <Typography variant="h6">CTO</Typography>
              <div className="value-block">
                <p>{init?.ctos?.name || "-"}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <DeletePopup
        open={openDeletePopup}
        handleClose={() => setDeletePopup(false)}
        handleDelete={(deleteUser) => handleDelete(deleteUser)}
        deleteUser={init?.id}
        loading={state.onlineRequest?.deletingOnlineRequest}
        error={error}
      />
      <Popup
        open={open}
        handleClose={handleClose}
        error={error}
        formik={formik}
        isEdit={isEdit}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleChip={handleChip}
        address={address}
        checkCustomerDivisionHaveManyCustomer={checkCustomerDivisionHaveManyCustomer}
      />
    </div>
  );
}
export default EditBlock;
