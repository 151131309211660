import moment from "moment-timezone";

import { postApi } from "@services/axios";
import { routes } from "@utils/constant";

const headerTop = () => {
  var wrapper = document.querySelector(".wrapper");
  if (wrapper !== null) {
    var header_h = document.getElementById("header").offsetHeight;
    wrapper.style.paddingTop = header_h + "px";
  }
};

// for mobile menu
const closeMobileMenu = () => {
  document.body.classList.remove("open-menu");
};
const setUserData = (data) => {
  localStorage.setItem("user", JSON.stringify(data));
};

const getUserData = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const setParentData = (data) => {
  localStorage.setItem("parentData", JSON.stringify(data));
};

const getParentData = () => {
  return JSON.parse(localStorage.getItem("parentData"));
};

const removeParentData = () => {
  return localStorage.removeItem("parentData");
};

const setEmail = (data) => {
  localStorage.setItem("email", data);
};

const getEmail = () => {
  return localStorage.getItem("email");
};

const removeEmail = () => {
  return localStorage.removeItem("email");
};

const removeUserData = () => {
  localStorage.removeItem("user");
};
const setToken = (token) => {
  localStorage.setItem("token", token);
};
const getUserId = () => {
  return localStorage.getItem("id");
};

const setUserId = (data) => {
  localStorage.setItem("id", data);
};

const removeUserId = () => {
  localStorage.removeItem("id");
};

const getToken = () => {
  return localStorage.getItem("token");
};

const setLogin = (isLogin) => {
  localStorage.setItem("is_login", isLogin);
};

const getLogin = () => {
  return localStorage.getItem("is_login");
};

const removeLogin = () => {
  return localStorage.removeItem("is_login");
};

const removeToken = () => {
  return localStorage.removeItem("token");
};

const setChildId = (token) => {
  localStorage.setItem("childs_id", token);
};

const getChildId = () => {
  return localStorage.getItem("childs_id");
};

const removeChildId = () => {
  return localStorage.removeItem("childs_id");
};

const getAccess = () => {
  return localStorage.getItem("access");
};

const setAccess = () => {
  return localStorage.setItem("access", "true");
};

const removeAccess = () => {
  return localStorage.removeItem("access");
};

const getTimeZone = () => {
  return localStorage.getItem("timezone");
};

const setTimeZone = (data) => {
  localStorage.setItem("timezone", data);
};

const removeTimeZone = () => {
  localStorage.removeItem("timezone");
};

const manageConnectedUser = (type, key, value = "") => {
  if (type === "get") {
    return localStorage.getItem(key);
  } else if (type === "set") {
    return localStorage.setItem(key, value);
  } else {
    return localStorage.removeItem(key);
  }
};

const setFilter = (filter, data, stringfy) => {
  if (stringfy) return localStorage.setItem(filter, JSON.stringify(data));
  else return localStorage.setItem(filter, data);
};
const getFilter = (filter, parse) => {
  if (parse) {
    return JSON.parse(localStorage.getItem(filter));
  } else {
    return localStorage.getItem(filter);
  }
};

const removeFilter = (filter) => {
  return localStorage.removeItem(filter);
};

const makeid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const allowOnlyNumbers = (event) => {
  if (event.key.length === 1 && /\D/.test(event.key)) {
    event.preventDefault();
  }
};
const allowOnlyCharacters = (event) => {
  var ASCIICode = event.charCode;
  if (
    (ASCIICode >= 65 && ASCIICode <= 90) ||
    (ASCIICode >= 97 && ASCIICode <= 122) ||
    ASCIICode === 32
  ) {
  } else {
    event.preventDefault();
  }
};

const allowOnlyFloat = (e) => {
  var newValue = e.target.value;
  if (hasDecimalPlace(newValue, 2) === false) {
    var ASCIICode = e.which ? e.which : e.keyCode;
    if (ASCIICode === 46) {
      if (newValue.split(".").length > 1 || e.target.selectionStart === 0) {
        e.preventDefault();
        e.returnValue = false;
      } else {
      }
    } else if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
    e.returnValue = false;
  }
};

const allowSomeSpecialChar = (e) => {
  var keyCode = e.keyCode || e.which;
  var pattern = /^[)(a-z\d\-_,.:;'°&/\s]+$/i;
  var isValid = pattern.test(String.fromCharCode(keyCode));
  if (!isValid) {
    e.preventDefault();
  } else {
  }
};

const awbNumberValidation = (e) => {
  var keyCode = e.keyCode || e.which;
  var pattern = /^[\d\-\s]+$/i;
  var isValid = pattern.test(String.fromCharCode(keyCode));
  if (!isValid) {
    e.preventDefault();
  } else {
    e.target.value = e.target.value
      .replace(/[^\d]/g, "")
      .replace(/(.{3})/, "$1-")
      .trim();
  }
};

const allowAlphaNumeric = (e) => {
  var keyCode = e.keyCode || e.which;
  var pattern = /^[a-z\d\-_\s`~!@#$%^&*()=+{\[\]}\\|:;'"<,>./?]+$/i;
  var isValid = pattern.test(String.fromCharCode(keyCode));
  if (!isValid) {
    e.preventDefault();
  } else {
  }
};

const getCustomDate = (to, from) => {
  const toDate = `${to.getDate()}/${to.getMonth() + 1}/${to.getFullYear()}`;
  const fromDate = `${from.getDate()}/${
    from.getMonth() + 1
  }/${from.getFullYear()}`;

  return `${toDate} - ${fromDate}`;
};
const UploadFile = (event, fileName, defaultText, mimeType, bucketType) => {
  return new Promise((resolve, reject) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      document.getElementById(fileName).innerHTML = file.name;
      let date = new Date();
      const name = date.getTime() + '.' + event.target.files[0].name?.split(".").pop();
      var reader = new FileReader();
      reader.onload = function () {
        postApi("getPreSignedURL", {
          fileName: name.trim(),
          mimeType: mimeType,
          bucketType: bucketType,
        })
          .then((response) => {
            fetch(response.data.data.preSignedUrl, {
              method: "PUT",
              body: new Blob([reader.result], {
                type: mimeType,
              }),
            })
              .then(() => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    } else {
      document.getElementById(fileName).innerHTML = defaultText;
    }
  });
};

const uploadUserImage = (event, mimeType, bucketType) => {
  return new Promise((resolve, reject) => {
    if (event.target.files && event.target.files.length) {
      let date = new Date();
      const name = date.getTime() + '.' + event.target.files[0].name?.split(".").pop();
      var reader = new FileReader();
      reader.onload = function () {
        postApi("getPreSignedURL", {
          fileName: name.trim(),
          mimeType: mimeType,
          bucketType: bucketType,
        })
          .then((response) => {
            fetch(response.data.data.preSignedUrl, {
              method: "PUT",
              body: new Blob([reader.result], {
                type: mimeType,
              }),
            })
              .then(() => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
    // else {
    //   document.getElementById(fileName).innerHTML = defaultText;
    // }
  });
};

const isAccessible = (path) => {
  if (getToken() && getAccess()) return true;
  if (getToken() && path === routes.welcome) return true;
  return false;
};

const getCustomFormToDate = (to, from) => {
  const toDate = moment(to).format("YYYY/MM/DD");
  const fromDate = moment(from).format("YYYY/MM/DD");
  return `${toDate} - ${fromDate}`;
};

const getCustomFormToDateFormat = (to, from) => {
  const toDate = moment(to).format("DD/MM/YYYY");
  const fromDate = moment(from).format("DD/MM/YYYY");
  return `${toDate} - ${fromDate}`;
};
function convertMinutesToHours(value) {
  var Hours = Math.floor(value / 60);
  var minutes = value % 60;
  return `${Hours} : ${minutes}`;
}

const utcToLocal = (time, timezone, format) => {
  if (time) {
    let localTime = moment.tz(time, timezone).format(format);
    return localTime;
  }
  return;
};

function requestStatusColors(data) {
  var color;
  switch (data) {
    case "Requested":
      color = "requested";
      break;
    case "Acknowledged":
      color = "acknowledged";
      break;
    case "Driver Assigned":
      color = "driver-assigned";
      break;
    case "Request Completed":
      color = "request-completed";
      break;

    default:
      color = "#e40000";
      break;
  }
  return color;
}
function hasDecimalPlace(value, x) {
  var pointIndex = value.indexOf(".");
  return pointIndex >= 0 && pointIndex < value.length - x;
}
const uploadImage = (event, mimeType, bucketType) => {
  return new Promise((resolve, reject) => {
    if (event.target.files && event.target.files.length) {
      let date = new Date();
      const name = date.getTime() + '.' + event.target.files[0].name?.split(".").pop();
      var reader = new FileReader();
      reader.onload = function () {
        postApi("getPreSignedURL", {
          fileName: name.trim(),
          mimeType: mimeType,
          bucketType: bucketType,
        })
          .then((response) => {
            fetch(response.data.data.preSignedUrl, {
              method: "PUT",
              body: new Blob([reader.result], {
                type: mimeType,
              }),
            })
              .then(() => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  });
};
const dashboardRequestStatusColors = (data) => {
  var color;
  switch (data) {
    case "Not Assigned":
      color = "not-assigned";
      break;
    case "Completed":
      color = "completed";
      break;
    case "Rejected":
      color = "rejected";
      break;
    case "In Transit":
      color = "in-progress";
      break;
    case "Change Driver":
      color = "in-progress";
      break;
    case "Lodgement":
      color = "in-progress";
      break;
    case "Loading":
      color = "in-progress";
      break;
    case "Unloading":
      color = "in-progress";
      break;
    case "Assigned":
      color = "assigned";
      break;
    case "Review Completed":
      color = "completed";
      break;

    default:
      color = "#e40000";
      break;
  }
  return color;
};
const utcToTimezone = (time, timezone, format) => {
  if (time && format) {
    let localTime = moment.tz(time, timezone).format(format);
    return localTime;
  }
  return;
};

const getCityIds = () => {
  let cityIds = [];
  if (getUserData()?.customerInfo) {
    getUserData().customerInfo.forEach(item => {
      cityIds.push(item.cities.id);
    });
  } else {
    cityIds.push(getUserData()?.cityId);
  }
  return cityIds;
}

const convertTempUnitToText = (unit) => {
  let text;
  if (unit.includes("C")) {
    text = "Celsius";
  } else if (unit.includes("K")) {
    text = "Kelvin";
  } else if (unit.includes("F")) {
    text = "Fahrenheit";
  } else {
    text = "Unknown unit";
  }
  return text;
};

export {
  headerTop,
  setToken,
  getToken,
  removeToken,
  closeMobileMenu,
  makeid,
  allowOnlyNumbers,
  allowOnlyCharacters,
  allowOnlyFloat,
  allowSomeSpecialChar,
  awbNumberValidation,
  getCustomDate,
  UploadFile,
  uploadUserImage,
  allowAlphaNumeric,
  setChildId,
  getChildId,
  removeChildId,
  getAccess,
  setAccess,
  removeAccess,
  isAccessible,
  getCustomFormToDate,
  convertMinutesToHours,
  utcToLocal,
  requestStatusColors,
  setUserId,
  getUserId,
  uploadImage,
  hasDecimalPlace,
  setFilter,
  getFilter,
  removeFilter,
  getUserData,
  setUserData,
  removeUserData,
  dashboardRequestStatusColors,
  utcToTimezone,
  setParentData,
  getParentData,
  removeParentData,
  setEmail,
  getEmail,
  removeEmail,
  removeUserId,
  setLogin,
  getLogin,
  removeLogin,
  manageConnectedUser,
  getTimeZone,
  setTimeZone,
  removeTimeZone,
  getCityIds,
  convertTempUnitToText,
  getCustomFormToDateFormat,
};
