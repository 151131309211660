import { LOGOUT } from "@utils/actionTypes";
import {
  removeToken,
  removeChildId,
  removeAccess,
  removeUserData,
  removeParentData,
  removeEmail,
  removeUserId,
  removeLogin,
  manageConnectedUser,
  removeTimeZone
} from "@utils/commonFunctions";

const logoutReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGOUT:
      removeToken();
      removeChildId();
      removeAccess();
      removeUserData();
      removeEmail();
      removeParentData();
      removeUserId();
      removeLogin();
      manageConnectedUser("remove", "connected_customer");
      removeTimeZone();
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default logoutReducer;
