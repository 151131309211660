import {
  IMAGE_UPLOAD,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_FAILURE,
  FETCH_CHILD_ACCOUNTS,
  FETCH_CHILD_ACCOUNTS_SUCCESS,
  FETCH_CHILD_ACCOUNTS_FAILURE,
  FETCH_EXISTING_CHILD_ACCOUNTS,
  GET_JOBTYPES,
  GET_JOBTYPES_SUCCESS,
  GET_JOBTYPES_FAILURE,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_CARGO_TYPE,
  GET_CARGO_TYPE_SUCCESS,
  GET_CARGO_TYPE_FAILURE,
  GET_CTOS,
  GET_CTOS_SUCCESS,
  GET_CTOS_FAILURE,
  GET_JOB_STATUS,
  GET_JOB_STATUS_SUCCESS,
  GET_JOB_STATUS_FAILURE,
  GET_REQUEST_STATUS,
  GET_REQUEST_STATUS_SUCCESS,
  GET_REQUEST_STATUS_FAILURE,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,
  RESET_DATA,
  GET_SOURCES,
  GET_SOURCES_SUCCESS,
  GET_SOURCES_FAILURE
} from "@utils/actionTypes";

const INIT_STATE = {
  imageUploading: false,
  loadingChildAccounts: false,
  childAccountsData: null,
  loadingJobType: false,
  jobTypeData: null,
  loadingCities: false,
  citiesData: null,
  loadingCargoType: false,
  cargoTypeData: null,
  loadingCtos: false,
  ctosData: null,
  loadingJobStatus: false,
  jobStatusData: null,
  loadingRequestStatus: false,
  requestStatusData: null,
  loadingAddress: false,
  addressData: null,
  sourcesData: null
};

const CommonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case IMAGE_UPLOAD:
      return { ...state, imageUploading: true };
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        imageUploading: false,
      };
    case IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        imageUploading: false,
      };

    case FETCH_CHILD_ACCOUNTS:
      return { ...state, loadingChildAccounts: true };
    case FETCH_CHILD_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loadingChildAccounts: false,
        childAccountsData: action.payload.data,
      };
    case FETCH_CHILD_ACCOUNTS_FAILURE:
      return {
        ...state,
        loadingChildAccounts: false,
        childAccountsData: action.payload.data,
      };
    case FETCH_EXISTING_CHILD_ACCOUNTS:
      return {
        ...state,
        loadingChildAccounts: false,
      };

    case GET_CARGO_TYPE:
      return { ...state, loadingCargoType: true };
    case GET_CARGO_TYPE_SUCCESS:
      return {
        ...state,
        cargoTypeData: action.payload,
        loadingCargoType: false,
      };
    case GET_CARGO_TYPE_FAILURE:
      return {
        ...state,
        cargoTypeData: action.payload,
        loadingCargoType: false,
      };

    case GET_CTOS:
      return { ...state, loadingCtos: true };
    case GET_CTOS_SUCCESS:
      return {
        ...state,
        ctosData: action.payload,
        loadingCtos: false,
      };
    case GET_CTOS_FAILURE:
      return {
        ...state,
        ctosData: action.payload,
        loadingCtos: false,
      };

    case GET_JOB_STATUS:
      return { ...state, loadingJobStatus: true };
    case GET_JOB_STATUS_SUCCESS:
      return {
        ...state,
        jobStatusData: action.payload,
        loadingJobStatus: false,
      };
    case GET_JOB_STATUS_FAILURE:
      return {
        ...state,
        jobStatusData: action.payload,
        loadingJobStatus: false,
      };

    case GET_REQUEST_STATUS:
      return { ...state, loadingRequestStatus: true };
    case GET_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        requestStatusData: action.payload,
        loadingRequestStatus: false,
      };
    case GET_REQUEST_STATUS_FAILURE:
      return {
        ...state,
        requestStatusData: action.payload,
        loadingRequestStatus: false,
      };

    case GET_JOBTYPES:
      return { ...state, loadingJobType: true };
    case GET_JOBTYPES_SUCCESS:
      return {
        ...state,
        jobTypeData: action.payload,
        loadingJobType: false,
      };
    case GET_JOBTYPES_FAILURE:
      return {
        ...state,
        jobTypeData: action.payload,
        loadingJobType: false,
      };

    case GET_CITIES:
      return { ...state, loadingCities: true };
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        citiesData: action.payload,
        loadingCities: false,
      };
    case GET_CITIES_FAILURE:
      return {
        ...state,
        citiesData: action.payload,
        loadingCities: false,
      };

    case GET_ADDRESS:
      return { ...state, loadingAddress: true };
    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        addressData: action.payload,
        loadingAddress: false,
      };
    case GET_ADDRESS_FAILURE:
      return {
        ...state,
        addressData: action.payload,
        loadingAddress: false,
      };

    case RESET_DATA:
      return INIT_STATE;
    case GET_SOURCES:
      return { ...state, loadingSources: true };
    case GET_SOURCES_SUCCESS:
      return {
        ...state,
        sourcesData: action.payload,
        loadingSources: false,
      };
    case GET_SOURCES_FAILURE:
      return {
        ...state,
        sourcesData: action.payload,
        loadingSources: false,
      };
    default:
      return state;
  }
};
export default CommonReducer;
