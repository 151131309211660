import React from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import {
  LOADING_TIME_ZONE,
  UNLOADING_TIME_ZONE,
} from "@utils/actionTypes";

import { useStore } from "@store/store";
import Header from "@components/header";
import Loader from "@components/loader";
import ProfileSideBar from "@components/profile-sidebar";
import { timeZoneStyle } from "./style";
import { ExpandMore } from "@material-ui/icons";
import { useFormik } from "formik";
import validationSchema from "@utils/validationSchemas";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { getTimeZone, getUserData, setTimeZone } from "../../../utils/commonFunctions";
import { toast } from "react-toastify";

function TimeZoneView() {
  const classes = timeZoneStyle();
  const materilClasses = materialCommonStyles();
  const [state, dispatch] = useStore();

  const formik = useFormik({
    initialValues: {
      timezone: getTimeZone() ? getTimeZone() : ""
    },
    validationSchema: validationSchema.timeZoneSchema,
    onSubmit: (value) => {
      dispatch({ type: LOADING_TIME_ZONE });
      setTimeZone((value.timezone));
      toast.success("Time Zone Updated Successfully");
      setTimeout(() => {
        dispatch({ type: UNLOADING_TIME_ZONE });
      }, 200)
    }
  });

  //Filter Time Zone List for removing duplicate object.
  const filterTimeZoneList = getUserData()?.customerInfo?.filter((value, index, self) => {
    return index === self.findIndex((t) => t.cities.timezone.trim() === value.cities.timezone.trim());
  })


  return (
    <>
      <Header />
      <div className={classes.timeZoneWrapper}>
        <div className="wrapper">
          <div className="container">
            <div className="profile-row-wrapper">
              <div className="left-sidebar">
                <ProfileSideBar></ProfileSideBar>
              </div>
              <div className="right-content">
                <Loader loading={state.timezone.loadingTimeZone} />
                <div className="white-card">
                  <Typography variant="h1">Time Zone</Typography>

                  <form
                    noValidate
                    autoComplete="off"
                    className={classes.customForm}
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="form-row">
                      <div className="form-group">
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                          <Select
                            displayEmpty
                            className={`${materilClasses.customSelect} fixed-select`}
                            variant="outlined"
                            MenuProps={{
                              classes: {paper: materilClasses.customSelect},
                            }}
                            onChange={formik.handleChange("timezone")}
                            value={formik.values.timezone}
                            IconComponent={() => <ExpandMore/>}
                          >
                            <MenuItem value={""} disabled>
                              Select Time Zone
                            </MenuItem>
                            {filterTimeZoneList.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.cities.timezone}>
                                  {item.cities.timezone}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText className="error-text">
                            {formik.touched.timezone && formik.errors.timezone}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>

                    <Button
                      className="orange-btn primary-btn"
                      color="inherit"
                      disableElevation
                      underlinenone="true"
                      type="submit"
                    >
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
);
}

export default TimeZoneView;
