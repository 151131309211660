import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Typography,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { useStore } from "@store/store";
import Header from "@components/header";
import Loader from "@components/loader";
import ProfileSideBar from "@components/profile-sidebar";
import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import checkedIcon from "@assets/images/checked-icon.svg";
import { ExpandMore } from "@material-ui/icons";
import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  SET_NOTIFICATION,
  SET_NOTIFICATION_SUCCESS,
  SET_NOTIFICATION_FAILURE,
  GET_CUSTOMER_DIVISION,
  GET_CUSTOMER_DIVISION_FAILURE,
  GET_CUSTOMER_DIVISION_SUCCESS
} from "@utils/actionTypes";
import { putApi, getApi } from "@services/axios";
import { notificationStyle } from "./style";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { getUserData } from "@utils/commonFunctions";

function NotificationView() {
  const classes = notificationStyle();
  const materilClasses = materialCommonStyles();
  const [state, dispatch] = useStore();
  const [data, setData] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const isConnectedCustomer = getUserData()?.connectedCustomer;
  const checkCustomerDivisionHaveManyCustomer = getUserData()?.customerInfo?.length > 1;

  const re = /([\w-.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})/;

  // API calling to get notification
  const getNotification = (customerId) => {
    let params = {
      ...( customerId ? { customerId } : {})
    };
    dispatch({ type: GET_NOTIFICATION });
    getApi("notifications",{ params }, false)
      .then((response) => {
        dispatch({
          type: GET_NOTIFICATION_SUCCESS,
          payload: response.data.data,
        });
        const temp = response.data.data.map((item) => {
          return {
            emails: !["", null].includes(item.emails)
              ? item.emails.split(",")
              : [],
            id: item.id,
            isEnable: item.isEnable === "0" ? false : true,
            notification: item.customerNotificationTypes?.name,
            reportType: item.reportType,
          };
        });
        setData(temp);
      })
      .catch((err) => {
        dispatch({ type: GET_NOTIFICATION_FAILURE, payload: err });
      });
  };

  let getDivisionCustomer = () => {
    dispatch({ type: GET_CUSTOMER_DIVISION });
    dispatch({ type: GET_NOTIFICATION });
    getApi(`users/getDivisionCustomer`)
        .then((response) => {
          dispatch({
            type: GET_CUSTOMER_DIVISION_SUCCESS,
            payload: response.data.data,
          });
          setCustomerId(response.data.data[0]?.id);
        })
        .catch((err) => {
          dispatch({ type: GET_CUSTOMER_DIVISION_FAILURE, payload: err });
        });
  };

  useEffect(() => {
    (isConnectedCustomer && checkCustomerDivisionHaveManyCustomer)
        ? getDivisionCustomer()
        : getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerId) {
      getNotification(customerId);
    }
  }, [customerId]);

  // Logic, Validation and API calling for updating notification
  const handleClick = () => {
    const temp = data.map((item, index) => {
      if (item.isEnable === true && item.emails.length === 0) {
        item.flagCheck = true;
        item.emailErr = "Email is required";
      } else {
        item.flagCheck = false;
      }
      return {
        ...item,
        emails: Array.isArray(item.emails)
          ? item.emails
          : item.emails.length && item.emails.split(","),
        isEnable: item.isEnable ? "1" : "0",
      };
    });
    const temp2 = data.map((item, index) => {
      if (item.isEnable === true && item.emails.length === 0) {
        item.flagCheck = true;
        item.emailErr = "Email is required";
      } else {
        item.flagCheck = false;
      }
      return {
        ...item,
      };
    });

    setData(temp2);
    let flag = data.find((x) => x.flag === true);
    let flagCheck = data.find((x) => x.flagCheck === true);
    if (flag?.flag !== true && flagCheck?.flagCheck !== true) {
      dispatch({ type: SET_NOTIFICATION });
      const params = {
        data: temp,
        ...( customerId ? { customerId } : {})
      };
      putApi("notifications", params)
        .then((response) => {
          dispatch({
            type: SET_NOTIFICATION_SUCCESS,
            payload: response.data.data,
          });
          getNotification(customerId);
          toast.success("Notification Updated Successfully");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          dispatch({ type: SET_NOTIFICATION_FAILURE, payload: err });
        });
    }
  };

  const handleChange = (e, type, id, item) => {
    if (type === "emails") item.emails.push(e);
    let newArray = [...data];
    newArray[id] = {
      ...newArray[id],
      [type]: type === "emails" ? item.emails : e.target.checked,
    };
    if (type === "emails") {
      if (e === "") {
        newArray[id].flag = true;
        newArray[id].emailErr = "Email is required";
      } else {
        if (re.test(e)) {
          const hasInvalidEmails = newArray[id].emails.some((item) => {
            return !re.test(item);
          });
          if (hasInvalidEmails === true) {
            newArray[id].flag = true;
            newArray[id].emailErr = "Email is invalid";
          } else {
            newArray[id].flag = false;
            newArray[id].flagCheck = false;
          }
        } else {
          newArray[id].flag = true;
          newArray[id].emailErr = "Email is invalid";
        }
      }
    }
    setData(newArray);
  };

  const handleDeleteChip = (e, id, item) => {
    const temp = item.emails.indexOf(e);
    item.emails.splice(temp, 1);
    const newArray = [...data];
    newArray[id] = {
      ...newArray[id],
      emails: item.emails,
    };
    const filterData = newArray[id].emails.some((item) => {
      return !re.test(item);
    });
    if (filterData === true) {
      newArray[id].flag = true;
      newArray[id].emailErr = "Email is invalid";
    } else {
      newArray[id].flag = false;
    }

    setData(newArray);
  };

  return (
    <>
      <Header />
      <div className={classes.notificationWrapper}>
        <Loader
          loading={
            state.notification.loadingNotification ||
            state.notification.settingNotification
          }
        />
        <div className="container">
          <div className="wrapper">
            <div className="profile-row-wrapper">
              <div className="left-sidebar">
                <ProfileSideBar />
              </div>
              <div className="right-content">
                <div className="white-card">
                  <form noValidate autoComplete="off" className="custom-form">
                    <div className="form-outer">
                      <div className="form-title">
                        <Typography variant="h2">Notifications</Typography>
                        { (isConnectedCustomer && checkCustomerDivisionHaveManyCustomer) && (
                          <div className="form-select-customer">
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <Select
                                value={customerId}
                                onChange={(e) => setCustomerId(e.target.value)}
                                displayEmpty
                                className={materilClasses.customSelect}
                                MenuProps={{
                                  classes: { paper: materilClasses.customSelect },
                                }}
                                IconComponent={() => <ExpandMore />}
                              >
                                <MenuItem value={""} disabled>Select Customer</MenuItem>
                                { state?.onlineRequest?.loadingCustomerDivision ? (
                                  <MenuItem>Loading...</MenuItem>
                                ) : (
                                  state?.onlineRequest?.customerDivisionData?.map((item, index) => {
                                    return (
                                      <MenuItem
                                          key={index}
                                          value={item.id}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </FormControl>
                            </div>
                        )}
                      </div>
                      { data?.map((item, index) => {
                        return (
                          <div className="form-row" key={index}>
                            <div className="form-group two-column">
                              <FormControl component="fieldset">
                                <FormGroup
                                  aria-label="position"
                                  className="custom-checkbox"
                                >
                                  <FormControlLabel
                                    value="end"
                                    control={
                                      <Checkbox
                                        name="isEnable"
                                        checked={item.isEnable}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            "isEnable",
                                            index,
                                            item
                                          )
                                        }
                                        icon={
                                          <img
                                            src={uncheckedIcon}
                                            alt="CheckBox"
                                          />
                                        }
                                        checkedIcon={
                                          <img
                                            src={checkedIcon}
                                            alt="CheckBox"
                                          />
                                        }
                                      />
                                    }
                                    label={item.notification}
                                    labelPlacement="end"
                                  />
                                </FormGroup>
                              </FormControl>
                            </div>
                            <div className="form-group two-column">
                              <ChipInput
                                className="chip-input"
                                name="emails"
                                value={item.emails}
                                onAdd={(e) =>
                                  handleChange(e, "emails", index, item)
                                }
                                onDelete={(e) =>
                                  handleDeleteChip(e, index, item)
                                }
                                placeholder="Email address"
                              />

                              <FormHelperText className="error-text">
                                {(item.flagCheck === true ||
                                  item.flag === true) &&
                                  item.emailErr !== "" &&
                                  item.emailErr}
                              </FormHelperText>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    { (customerId || !(isConnectedCustomer && checkCustomerDivisionHaveManyCustomer)) && (
                      <div className="form-row btn-wrapper">
                        <Button
                          className="orange-btn lg"
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={handleClick}
                        >
                          SAVE CHANGES
                        </Button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationView;
