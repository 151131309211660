import login from "./login";
import logout from "./logout";
import profile from "./profile";
import changePassword from "./changePassword";
import notification from "./notification";
import common from "./common";
import invoice from "./invoices";
import onlineRequest from "./online-request";
import dashboard from "./dashboard";
import mapCockpit from "./mapcockpit";
import report from "./report";
import job from "./jobs";
import redirection from "./redirection";
import awbCosn from "./awb-consignment";
import trucks from "./trucks";
import driver from "./driver";
import timezone from "./timezone";

const reducer = {
  login,
  logout,
  profile,
  changePassword,
  notification,
  common,
  invoice,
  onlineRequest,
  dashboard,
  mapCockpit,
  report,
  job,
  redirection,
  awbCosn,
  trucks,
  driver,
  timezone
};

export default reducer;
