import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { theme } from "@utils/theme";
import { routes } from "@utils/constant";
import { headerTop, closeMobileMenu } from "@utils/commonFunctions";
import PrivateRoute from "@utils/privateRoute";

import PageNotFoundView from "@views/404";
import InvoiceView from "@views/invoice";
import MyProfileView from "@views/profile/my-profile";
import ChildAccountView from "@views/profile/child-account";
import NotificationView from "@views/profile/notifications";
import IntegrationView from "@views/profile/integration";
import DashboardView from "@views/dashboard";
import LoginView from "@views/login";
import WelcomePage from "@views/welcome";
import ParentCustomer from "@views/parent-customer";
import MapCockpitView from "@views/mapCockpit";
import OnlineReport from "@views/report";
import OnlineRequestView from "@views/online-request";
import OnlineRequestDetailView from "@views/online-request-detail";
import VerifyEmailView from "@views/profile/verify-email";
import JobListing from "@views/job-listing";
import JobDetailView from "@views/job-detail";
import AWB from "@views/profile/awb";
import Consignment from "@views/profile/consignment";
import Upload from "@views/profile/upload"
import TimeZoneView from "@views/profile/timezone";
import { getUserData } from "./utils/commonFunctions";

const useUpdater = () => {
  const location = useLocation();
  useEffect(() => {
    headerTop();
    closeMobileMenu();
    window.scrollTo(0, 0);
    document.body.classList.remove("open-filter");
  }, [location]);
};

const UpdateWrapper = () => {
  useUpdater();

  return (
    <Switch>
      <Route exact path={routes.login} component={LoginView} />
      <Route exact path={routes.verifyEmail} component={VerifyEmailView} />
      <PrivateRoute authed={true} exact path={routes.welcome} component={WelcomePage} />
      <PrivateRoute authed={true} exact path={"/"} component={DashboardView} />
      <PrivateRoute authed={true} exact path={routes.dashboard} component={DashboardView} />
      <PrivateRoute authed={true} exact path={routes.myProfile} component={MyProfileView} />
      <PrivateRoute
        authed={true}
        exact
        path={routes.childAccount}
        component={ChildAccountView}
      />
      <PrivateRoute
        authed={true}
        exact
        path={routes.notifications}
        component={NotificationView}
      />
      <PrivateRoute
        authed={true}
        exact
        path={routes.integration}
        component={IntegrationView}
      />
      <PrivateRoute authed={true} exact path={routes.jobListing} component={JobListing} />
      <PrivateRoute
        authed={true}
        exact
        path={`${routes.jobDetail}/:id`}
        component={JobDetailView}
      />
      <PrivateRoute
        authed={true}
        exact
        path={routes.pageNotFound}
        component={PageNotFoundView}
      />
      <PrivateRoute authed={true} exact path={routes.mapCockpit} component={MapCockpitView} />
      <PrivateRoute authed={true} exact path={routes.report} component={OnlineReport} />
      <PrivateRoute
        authed={true}
        exact
        path={routes.onlineRequest}
        component={OnlineRequestView}
      />
      <PrivateRoute authed={true} exact path={routes.awb} component={AWB} />
      <PrivateRoute authed={true} exact path={routes.consignment} component={Consignment} />
      <PrivateRoute authed={true} exact path={routes.upload} component={Upload} />
      <PrivateRoute
        authed={true}
        exact
        path={`${routes.onlinRequestDetail}/:id`}
        component={OnlineRequestDetailView}
      />
      <PrivateRoute authed={true} exact path={routes.invoice} component={InvoiceView} />
      <PrivateRoute
        authed={getUserData()?.connectedCustomer && getUserData()?.customerInfo?.length > 1}
        exact
        path={routes.timezone}
        component={TimeZoneView}
      />
      <Redirect to={routes.pageNotFound} />
    </Switch>
  );
};

const Routes = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <UpdateWrapper />
      </Router>
    </ThemeProvider>
  );
};

export default Routes;
