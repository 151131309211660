import { makeStyles } from "@material-ui/core/styles";

const timeZoneStyle = makeStyles((theme) => ({
  timeZoneWrapper: {
    "& .MuiBackdrop-root": {
      color: " #fff",
      zIndex: "1201",
    },
  },
  customForm: {
    "& .form-row": {
      display: "flex",
      margin: "0 -10px",
      "@media (max-width: 575px)": {
        flexWrap: "wrap",
      },
      "& .form-group": {
        padding: "0 10px",
        // maxWidth: "50%",
        flex: "1",
        marginBottom: "20px",
        "& .error-text": {
          color: "red",
        },
        "& .fixed-select": {
          maxWidth: "460px",
        }
      },
    },
  }
}));

export { timeZoneStyle };
