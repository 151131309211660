import React from "react";
import {
  Zoom,
  Tooltip as HtmlTooltip,
  Select,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ChipInput from "material-ui-chip-input";

import { useStore } from "@store/store";
import infoIcon from "@assets/images/information-icon.svg";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { allowAlphaNumeric, allowOnlyNumbers, getUserData } from "@utils/commonFunctions";

function DetailForm(props) {
  const materilClasses = materialCommonStyles();
  const [state] = useStore();

  const handleDeleteChip = (e) => {
    const temp = props.formik.values.cc?.indexOf(e);
    props.formik.values.cc.splice(temp, 1);
    props.formik.setFieldValue("cc", props.formik.values.cc);
  };

  return (
    <>
      <div className="form-row">
        <div className="form-gourp two-column">
          <FormControl variant="outlined">
            <label className="label-text">Reference No</label>
            <TextField
              id="referenceNo"
              placeholder="Reference Number"
              variant="outlined"
              type="text"
              name="referenceNo"
              onKeyPress={allowAlphaNumeric}
              onChange={props.formik.handleChange}
              value={props.formik.values.referenceNo}
              error={
                props.formik.touched.referenceNo &&
                Boolean(props.formik.errors.referenceNo)
              }
              helperText={
                props.formik.touched.referenceNo &&
                props.formik.errors.referenceNo
              }
            />
          </FormControl>
        </div>

        <div className="form-gourp two-column">
          <FormControl variant="outlined">
            <label className="label-text">Type of Job</label>
            <Select
              labelId="type-of-job-label"
              id="jobTypeId"
              name="jobTypeId"
              value={props.formik.values.jobTypeId}
              onChange={props.formik.handleChange}
              displayEmpty
              className={materilClasses.customSelect}
              MenuProps={{
                classes: { paper: materilClasses.customSelect },
              }}
              IconComponent={() => <ExpandMore />}
            >
              <MenuItem value={""} disabled>
                Select type of job
              </MenuItem>
              {state?.common?.loadingJobType ? (
                <MenuItem>Loading...</MenuItem>
              ) : (
                state?.common?.jobTypeData?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item.id}
                      onClick={() =>
                        props.formik.setFieldValue("jobTypeLabel", item.name)
                      }
                    >
                      {item.name}
                    </MenuItem>
                  );
                })
              )}
            </Select>
            <FormHelperText className="error-text">
              {props.formik.touched.jobTypeId && props.formik.errors.jobTypeId}
            </FormHelperText>
          </FormControl>
        </div>
      </div>
      <div className="form-row">
        <div className="form-gourp two-column">
          <FormControl variant="outlined">
            <label className="label-text">Requester Name</label>
            <TextField
              id="requesterName"
              placeholder="Requester name"
              variant="outlined"
              type="text"
              name="requesterName"
              onKeyPress={allowAlphaNumeric}
              onChange={props.formik.handleChange}
              value={props.formik.values.requesterName}
              error={
                props.formik.touched.requesterName &&
                Boolean(props.formik.errors.requesterName)
              }
              helperText={
                props.formik.touched.requesterName &&
                props.formik.errors.requesterName
              }
            />
          </FormControl>
        </div>
        <div className="form-gourp two-column">
          <FormControl variant="outlined">
            <label className="label-text">Email ID</label>
            <TextField
              id="email"
              placeholder="Email ID"
              variant="outlined"
              type="text"
              name="email"
              onChange={props.formik.handleChange}
              value={props.formik.values.email}
              error={
                props.formik.touched.email && Boolean(props.formik.errors.email)
              }
              helperText={
                props.formik.touched.email && props.formik.errors.email
              }
            />
          </FormControl>
        </div>
      </div>
      <div className="form-row">
        <div className="form-gourp two-column">
          <FormControl variant="outlined">
            <label className="label-text">
              CC
              <HtmlTooltip
                TransitionComponent={Zoom}
                title={
                  <>
                    <p>Email</p>
                  </>
                }
                interactive
                arrow
                placement="right"
                classes={{ tooltip: materilClasses.modalTooltip }}
                enterTouchDelay={1}
              >
                <span className="info-icon">
                  {" "}
                  <img src={infoIcon} alt="Info" />
                </span>
                </HtmlTooltip>
              </label>
              <ChipInput
                  placeholder="CC"
                  className="chip-input"
                  name="cc"
                  id="cc"
                  onChange={props.handleChip}
                  onDelete={handleDeleteChip}
                  value={props.formik.values.cc}
                  error={props.formik.touched.cc && Boolean(props.formik.errors.cc)}
                  helperText={
                      props.formik.touched.cc && [...new Set(props.formik.errors.cc)]
                  }
              />
            </FormControl>
          </div>
          <div className="form-gourp two-column">
            <FormControl variant="outlined">
              <label className="label-text">Quantity</label>
              <TextField
                  id="quantity"
                  placeholder="Quantity"
                  variant="outlined"
                  type="text"
                  name="quantity"
                  onKeyPress={allowOnlyNumbers}
                  onChange={props.formik.handleChange}
                  value={props.formik.values.quantity}
                  disabled={
                    ![0, "Empty"].includes(props.formik.values.jobTypeLabel)
                  }
                  error={
                      props.formik.touched.quantity &&
                      Boolean(props.formik.errors.quantity)
                  }
                  helperText={
                      props.formik.touched.quantity && props.formik.errors.quantity
                  }
              />
            </FormControl>
          </div>
        </div>
        <div className="form-row">
          <div className="form-gourp two-column">
            <label className="label-text">Cargo Type</label>
            <FormControl
                variant="outlined"
                className={materilClasses.customSelect}
            >
              <Select
                  displayEmpty
                  className={materilClasses.customSelect}
                  MenuProps={{
                    classes: {paper: materilClasses.customSelect},
                  }}
                  onChange={props.formik.handleChange("cargoTypeId")}
                  value={props.formik.values.cargoTypeId}
                  IconComponent={() => <ExpandMore/>}
                  disabled={[
                    "Transfer",
                    "Interstate",
                    "Ad-Hoc",
                    "Airside",
                    "Metro",
                    "Empty",
                  ].includes(props.formik.values.jobTypeLabel)}
              >
                <MenuItem value={""} disabled>
                  Select Cargo Type
                </MenuItem>
                {state?.common?.loadingCargoType ? (
                    <MenuItem>Loading...</MenuItem>
                ) : (
                    state?.common?.cargoTypeData?.map((item, index) => {
                      return (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                      );
                    })
                )}
              </Select>
              <FormHelperText className="error-text">
                {props.formik.touched.cargoTypeId &&
                    props.formik.errors.cargoTypeId}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
        { (getUserData()?.connectedCustomer && props.checkCustomerDivisionHaveManyCustomer) &&
          <div className="form-row">
            <div className="form-gourp full-width-column">
              <label className="label-text">Division</label>
              <FormControl
                variant="outlined"
                className={materilClasses.customSelect}
              >
                <Select
                  displayEmpty
                  className={materilClasses.customSelect}
                  MenuProps={{
                    classes: {paper: materilClasses.customSelect},
                  }}
                  onChange={props.formik.handleChange("customerDivisionId")}
                  value={props.formik.values.customerDivisionId}
                  disabled={!!props.isEdit}
                  IconComponent={() => <ExpandMore/>}
                >
                  <MenuItem value={""} disabled>
                    Select Division
                  </MenuItem>
                  {state?.onlineRequest?.loadingCustomerDivision ? (
                    <MenuItem>Loading...</MenuItem>
                  ) : (
                    state?.onlineRequest?.customerDivisionData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
                <FormHelperText className="error-text">
                  {props.formik.touched.customerDivisionId && props.formik.errors.customerDivisionId}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
      }
      </>
  );
}

export default DetailForm;
