import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@utils/constant";

const notificationStyle = makeStyles((theme) => ({
  notificationWrapper: {
    "& .MuiBackdrop-root": {
      color: " #fff",
      zIndex: "1201",
    },
    "& .btn-wrapper": {
      marginTop: "20px",
    },
    "& h2": {
      // marginBottom: "21px !important",
    },
    "& .form-outer": {
      "& .form-title": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "21px",
        "@media (max-width: 991px)": {
          flexDirection: "column",
        },
        "& .form-select-customer": {
          flex: "0 0 300px",
          "@media (max-width: 991px)": {
            flex: "0 0 100%",
          },
        }
      },
      "& .form-row": {
        display: "flex",
        margin: "0 -15px",
        "@media (max-width: 767px)": {
          flexWrap: "wrap",
        },
        "& .form-group": {
          marginBottom: "25px",
        },
        "& .two-column": {
          flex: "0 0 50%",
          maxWidth: "50%",
          padding: "0 15px",
          "& .chip-input": {
            "& > div": {
              minHeight: "50px",
              border: "1px solid #e7e4f1",
              marginBottom: 0,
              borderRadius: "6px",
              alignItems: "center",
              padding: "10px 15px 0",
              overflow: "hidden",
              "&::before": {
                display: "none",
              },
              "&::focus": {
                border: "none",
              },
              "& input": {
                border: "none",
                padding: "0 !important",
              },
            },
            "& .MuiChip-root": {
              height: "28px",
              lineHeight: "28px",
              backgroundColor: colors.lightGraybg,
              fontSize: "16px",
              color: colors.black,
              borderRadius: "30px",
            },
            "& .MuiInput-root": {
              marginTop: "-10px",
              "& input": {
                height: "38px",
                lineHeight: "38px",
              },
            },
          },
          "& .error-text": {
            color: "red",
          },
          "&.chip-input-wrapper": {
            "& .label-text": {
              display: "flex",
              alignItems: "center",
            },
          },
          "@media (max-width: 767px)": {
            flex: "0 0 100%",
            maxWidth: "100%",
          },
        },
      },
    },
  },
}));

export { notificationStyle };
