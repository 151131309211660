const apiUrl = process.env.REACT_APP_API_URL;
const colors = {
	primary: "#00548e",
	white: "#FFFFFF",
	black: "#212121",
	graybg: "#5b646b",
	orange: "#f79239",
	red: "#ff4848",
	skyblue: "#00aeef",
	grayborder: "#e3e3e3",
	lightBlack: "#464646",
	lightGray: "#f8f8f8",
	lightGraybg: "#f6f6f6",
	borderGray: "#e7e4f1",
	darkGray: "#535353",
	darkRed: "#e40000",
	darkBlue: "#1e3669",
	lightBlue: "#0088ce",
	red2: "#e43232",
	lightblue1: "#e5f8ff",
	lightBlue2: "#BDD7EE",
	gray2: "#f4f4f4",
	gray3: "#727272",
	gray4: "#fafafa",
	gray5: "#b2b2b2",
	gray6: "#F0F1F3",
	gray7: "#686868",
	gray8: "#f9f9f9",
	gray9: "#787878",
	gray10: "#9c9ea2",
	lightbg: "#f8f8f8",
	green: "#17d113",
	red1: "#f05527",
	yellow1: "#f5c32e",
	green1: "#006c15",
	darkGreen: "#26da8b",
	lightGreen: "#c9dc38",
	lightRed1: "#fd5c24",
	darkRed1: "#ee1614",
	light_black: "#686868",
	green2: "#27ae60",
};

const routes = {
	pageNotFound: "/page-not-found",
	login: "/login",
	invoice: "/invoice",
	onlineRequest: "/online-request",
	myProfile: "/my-profile",
	childAccount: "/child-account",
	notifications: "/notifications",
	integration: "/integration",
	timezone: "/timezone",
	report: "/report",
	dashboard: "/dashboard",
	welcome: "/welcome",
	parentCustomer: "/parent-customer",
	mapCockpit: "/map-cockpit",
	onlinRequestDetail: "/online-request-detail",
	verifyEmail: "/users/verifyEmail",
	forgotPassword: "/forgot-password",
	jobListing: "/job-listing",
	jobDetail: "/job-detail",
	awb: "/air-way-bill",
	consignment: "/consignment",
	upload: "/upload",
	AWBSearch: "/awb-search",
};

const reportsHeading = [
	{ title: "Job ID", className: "jobId-cell" },
	{ title: "Type of Job", className: "jobType" },
	{ title: "Type", className: "reportType" },
	{ title: "Air Way Bill", className: "airBill" },
	{ title: "Child Customer", className: "customer" },
	{ title: "Qty", className: "pieces" },
	{ title: "Weight(kg)", className: "weight" },
	{ title: "CTO", className: "cto" },
	{ title: "Online Request Status", className: "request-status" },
	{ title: "Job Status", className: "jobStatus" },
	{ title: "Reference No", className: "refNo" },
	{ title: "Total Job Duration", className: "jobDuration" },
	{ title: "Truck Rego", className: "truckRego" },
];

const requestHeading = [
	{ title: "Id" },
	{ title: "Customer Name" },
	{ title: "Type of Job" },
	{ title: "Cargo Type" },
	{ title: "CTO" },
	{ title: "Online Request Status" },
	{ title: "Job Status" },
	{ title: "Reference No." },
	{ title: "Chat" },
];

const invoiceHeading = [
	{ title: "Invoice Number", className: "invoice-number" },
	{ title: "Issue Date", className: "issue-date" },
	{ title: "Bill-To", className: "bill-to" },
	{ title: "Total Quantity", className: "total-pieces" },
	{ title: "Total Weight", className: "total-weight" },
	{ title: "Gross Amount", className: "gross-amount" },
	{ title: "Due Date", className: "due-date" },
	{ title: "Invoice", className: "invoice" },
];

const addLooseWayData = [
	{
		id: "456-1245 3480",
		flight: "SQ 0231 25 May, 10:09 (A)",
		weight: "45 Kg",
		pieces: "3/10",
		loose: "12345, 92345",
	},
	{
		id: "368-3758 6483",
		flight: "SQ 0231 25 May, 10:09 (A)",
		carrier: "Emirates",
		weight: "50 Kg",
		pieces: "0/10",
		loose: "4759",
	},
	{
		id: "294-3759 7494",
		flight: "SQ 0231 25 May, 10:09 (A)",
		carrier: "Emirates",
		weight: "230 Kg",
		pieces: "0/10",
		loose: "3785, 2749, 3895",
	},
];

const consignMentData = [
	{
		id: "8324 4242 kf",
		data: [
			{ consNo: "p-2324", weight: "20.0 kg", widthLength: "W 3.5m L 2.0 m" },
			{ consNo: "p-2324", weight: "20.0 kg", widthLength: "W 3.5m L 2.0 m" },
		],
	},
	{
		id: "8324 4242 kg",
		data: [
			{ consNo: "p-2324", weight: "20.0 kg", widthLength: "W 3.5m L 2.0 m" },
			{ consNo: "p-2324", weight: "20.0 kg", widthLength: "W 3.5m L 2.0 m" },
		],
	},
];
const addWayBillsData = [
	{
		id: "248-2345 5002",
		flight: "SQ 0231 25 May, 10:09 (A)",
		carrier: "Emirates",
		weight: "500 Kg",
		pieces: "0/10",
		ULD: "AKE 23254 QA, AKE 09234 AF, AKE 223210 AF",
	},
	{
		id: "248-2345 5002",
		flight: "SQ 0231 25 May, 10:09 (A)",
		carrier: "Emirates",
		weight: "500 Kg",
		pieces: "0/10",
		ULD: "AKE 23254 QA, AKE 09234 AF, AKE 223210 AF",
	},
	{
		id: "248-2345 5002",
		flight: "SQ 0231 25 May, 10:09 (A)",
		carrier: "Emirates",
		weight: "500 Kg",
		pieces: "0/10",
		ULD: "AKE 23254 QA, AKE 09234 AF, AKE 223210 AF",
	},
];
const awbByAwbNumberHeading = [
	{ title: "AWB Number", className: "awb-number" },
	{ title: "Job Number", className: "jobId-cell" },
	{ title: "Job Type", className: "jobType" },
	{ title: "Cargo Type", className: "reportType" },
	{
		title: "Job Started Date",
		className: "job-started-date",
	},
	{
		title: "Job Completed Date",
		className: "job-completed-date",
	},
	{ title: "Total Qty", className: "total-qty" },
	{ title: "Total Weight", className: "total-weight" },
	{ title: "Loaded Qty", className: "loaded-qty" },
	{ title: "Loaded Weight", className: "loaded-weight" },
	{ title: "Remainder Reason", className: "remainder-reason" },
	{ title: "CTO", className: "cto" },
	{ title: "Job Status", className: "jobStatus" },
	{ title: "Run Sheet", className: "run-sheet" },
];
const reportsHeadingULDLoose = [
	{ title: "Job ID", className: "jobId-cell" },
	{ title: "Type of Job", className: "jobType" },
	{ title: "Air Way Bill", className: "airBill" },
	{ title: "Child Customer", className: "customer" },
	{ title: "Qty", className: "pieces" },
	{ title: "Weight(kg)", className: "weight" },
	{ title: "CTO", className: "cto" },
	{ title: "Online Request Status", className: "request-status" },
	{ title: "Job Status", className: "jobStatus" },
	{ title: "Reference No", className: "refNo" },
	{ title: "Total Job Duration", className: "jobDuration" },
	{ title: "Truck Rego", className: "truckRego" },
];
const dashboardListingHeader = [
	{ title: "Job ID", className: "jobId", sort: true, sortTitle: "id" },
	{ title: "Customer", className: "customerName", sort: true, sortTitle: "customerName" },
	{ title: "Driver", className: "driver", sort: true, sortTitle: "driver" },
	{
		title: "Truck Rego",
		className: "truck-rego",
		sort: true,
		sortTitle: "truckRego",
	},
	{ title: "Status", className: "status", sort: true, sortTitle: "jobStatus" },
	{ title: "CTO", className: "cto", sort: true, sortTitle: "cto" },
	{
		title: "Type of job",
		className: "jobType",
		sort: true,
		sortTitle: "jobType",
	},
	{
		title: "Cargo Type",
		className: "cargoType",
		sort: true,
		sortTitle: "cargoType",
	},
	{ title: "Date", className: "date", sort: true, sortTitle: "date" },
	{
		title: "Completion Date",
		className: "completion-date",
		sort: true,
		sortTitle: "completedAt",
	},
];
const jobListing = [
	{
		jobId: "FSCKJ890",
		cName: "ABC Pty Ltd",
		fromTo: "Trends malll street",
		airBill: "932-2345 3402, 258-2345 8002",
		date: "10/6/2021",
		truckRego: "HJKOP90",
		driver: "Shawn",
		status: "In Transit",
		jobType: "Import",
		cargoType: "ULD",
		CTO: "Dnata",
	},
	{
		jobId: "RIT50",
		cName: "Alpine Pty Ltd",
		fromTo: "280 Beames Ave, Mount Druitt NSW 2770",
		airBill: "123-456-7890, 456-898 78677",
		date: "10/6/2021",
		truckRego: "HJKOP90",
		driver: "ABC",
		status: "Not Assigned",
		jobType: "Export",
		cargoType: "Loose",
		CTO: "Menzies T1",
	},
	{
		jobId: "RIT565",
		cName: "X-import Pty Ltd",
		fromTo: "456 Street, Sydney, NSW 189",
		airBill: "258-2345 8002, 932-2345 3402",
		date: "10/6/2021",
		truckRego: "TREN89",
		driver: "John",
		status: "Completed",
		jobType: "Airside Transfer",
		cargoType: "ULD",
		CTO: "Qantis T1",
	},
	{
		jobId: "RYN470",
		cName: "XYZ Pty Ltd",
		fromTo: "13/172 Green Valley Rd,Green Valley NSW 2168 34 Roseberry road",
		airBill: "906-784-7346,789-565-670-5474",
		date: "10/10/2021",
		truckRego: "TREN89",
		driver: "Stephen",
		status: "Lodgement",
		jobType: "Empty ULD",
		cargoType: "ULD",
		CTO: "Menzies T1",
	},
	{
		jobId: "QWTF90",
		cName: "DHL",
		fromTo: "456 Street, Sydney, NSW 189",
		airBill: "906-784-7346,789-565-670-8909",
		date: "6/6/2021",
		truckRego: "GHJK40",
		driver: "Anand",
		status: "Assigned",
		jobType: "Ad Hoc",
		cargoType: "ULD",
		CTO: "Dnata",
	},
	{
		jobId: "DKL4960",
		cName: "Alpine Pty Ltd",
		fromTo: "280 Beames Ave, Mount Druitt NSW 2770",
		airBill: "123-456-7890, 456-898 78677",
		date: "10/6/2021",
		truckRego: "HJKOP90",
		driver: "Rahul",
		status: "Not Assigned",
		jobType: "Metro",
		cargoType: "Loose",
		CTO: "Qantis T1",
	},
	{
		jobId: "Zea67",
		cName: "Child Pty Ltd",
		fromTo: "280 Beames Ave, Mount Druitt NSW 2770",
		airBill: "123-456-7890, 456-898 78677",
		date: "112/5/2021",
		truckRego: "HJKOP90",
		driver: "Rohan",
		status: "Completed",
		jobType: "Interstate",
		cargoType: "ULD",
		CTO: "Menzies T1",
	},
	{
		jobId: "QWTF91",
		cName: "DHL",
		fromTo: "456 Street, Sydney, NSW 189",
		airBill: "906-784-7346,789-565-670-8909",
		date: "6/6/2021",
		truckRego: "GHJK40",
		driver: "Arvind",
		status: "Completed",
		jobType: "Temp Control",
		cargoType: "Loose",
		CTO: "Dnata",
	},
];
const runSheetData = [
	{
		status: "Started",
		time: "08:32",
		location: "2-6 Star Ct, Cambridge Gardens NSW 2747",
		duration: "27/11/2021 12:00:36",
	},
	{
		status: "Arrived at Pickup Location",
		time: "08:45",
		location: "2-6 Star Ct, Cambridge Gardens NSW 2747",
		duration: "27/11/2021 12:19:36",
	},
	{
		status: "Start Loading",
		time: "09:59",
		location: "2-6 Star Ct, Cambridge Gardens NSW 2747",
		duration: "27/11/2021 12:29:36",
	},
	{
		status: "Finish Loading",
		time: "12:00",
		location: "1008 Botany Rd, Mascot NSW 2020 - Toll road 23 km",
		duration: "27/11/2021 12:39:36",
	},
	{
		status: "Start Break",
		time: "12:30 ",
		location: "45 Karril Ave, Beecroft NSW 2119",
		duration: "27/11/2021 02:19:20",
	},
	{
		status: "Finish Break",
		time: "13:00",
		location: "45 Karril Ave, Beecroft NSW 2119",
		duration: "27/11/2021 02:19:20",
	},
	{
		status: "Arrived at Delivery Location",
		time: "13:44",
		location: "Dr street ave, Beetcraft MTR 890",
		duration: "27/11/2021 02:19:34",
	},
	{
		status: "Start Unloading",
		time: "14:00",
		location: "45 Karril Ave, Beecroft NSW 2119",
		duration: "27/11/2021 02:19:35",
	},
	{
		status: "Finish Unloading",
		time: "16:00",
		location: "Dr street ave, Beetcraft MTR 970",
		duration: "27/11/2021 02:19:35",
	},
	{
		status: "Finished",
		time: "16:10",
		location: "45 Karril Ave, Beecroft NSW 2119",
		duration: "27/11/2021 02:19:36",
	},
];

const customerDetailAWBHeader = [
	{ title: "AWB", className: "awb", sort: "true", sortTitle: "awb" },
	{
		title: "Airline",
		className: "airline",
		sort: "true",
		sortTitle: "airline",
	},
	{
		title: "Total Weight",
		className: "weight",
		sort: "true",
		sortTitle: "weight",
	},
	{
		title: "Total Quantity",
		className: "quantity",
		sort: "true",
		sortTitle: "quantity",
	},
	{
		title: "Ready Date",
		className: "readyDate",
		sort: "true",
		sortTitle: "readyDate",
	},
	{
		title: "Ready Time",
		className: "readyTime",
		sort: "true",
		sortTitle: "readyTate",
	},
	{
		title: "Cut-Off Time",
		className: "cutOffTime",
		sort: "true",
		sortTitle: "cutOffTime",
	},
	{
		title: "Customer",
		className: "customer",
		sort: "true",
		sortTitle: "name",
	},
	{
		title: "Action",
		className: "action",
		sort: "false",
	},
];

const customerDetailConsignmentHeader = [
	{
		title: "Consignment",
		className: "consignment",
		sort: "true",
		sortTitle: "number",
	},
	{
		title: "Total Weight",
		className: "weight",
		sort: "true",
		sortTitle: "weight",
	},
	{
		title: "Total Quantity",
		className: "quantity",
		sort: "true",
		sortTitle: "quantity",
	},
	{
		title: "Customer",
		className: "customer",
		sort: "true",
		sortTitle: "name",
	},
	{
		title: "Action",
		className: "action",
		sort: "false",
	},
];

const integrationApiPermissions = {
	NO_ACCESS: 1,
	READ_ONLY: 2,
	READ_AND_WRITE: 3
};

const integrationTokenHeading = [
	{ title: "Name Token", className: "nameToken" },
	{ title: "Customer", className: "customer" },
	{ title: "Permission", className: "permission" },
	{ title: "Integration Permission", className: "integrationPermission" },
];
const rowsPerPageVal = 100;

const jobStatus = {
	NOT_ASSIGNED: "Not Assigned",
	ASSIGNED: "Assigned",
	REJECTED: "Rejected",
	IN_TRANSIT: "In Transit",
	COMPLETED: "Completed",
	REVIEW_COMPLETED: "Review Completed",
	LODGEMENT: "Lodgement",
	LOADING: "Loading",
	UNLOADING: "Unloading",
	CHANGE_DRIVER: "Change Driver",
};

const dataJobStatus = [
	{
		"id": 1,
		"name": "Not Assigned"
	},
	{
		"id": 2,
		"name": "Assigned"
	},
	{
		"id": 3,
		"name": "Rejected"
	},
	{
		"id": 4,
		"name": "In Transit"
	},
	{
		"id": 5,
		"name": "Completed"
	},
	{
		"id": 6,
		"name": "Review Completed"
	},
	{
		"id": 7,
		"name": "Lodgement"
	},
	{
		"id": 8,
		"name": "Loading"
	},
	{
		"id": 9,
		"name": "Unloading"
	},
	{
		"id": 10,
		"name": "Change Driver"
	}
];

const jobRunSheetStatus = {
	START_BREAK: 'START_BREAK',
	FINISH_BREAK: 'FINISH_BREAK'
}

const sourceId = {
	ADMIN: 1,
	CUSTOMER: 2,
	DRIVER: 3,
	CARGO_WISE: 4
}

const temperatureTrackingHeading = [
	{ title: "Status", className: "time-zone", sort: true },
	{ title: "Vehicle", className: "time-zone", sort: true },
	{ title: "Date", className: "time-zone", sort: true },
	{ title: "Set Point 1", className: "time-zone", sort: true },
	{ title: "Return Air 1", className: "time-zone", sort: true },
	{ title: "Discharge Air 1", className: "time-zone", sort: true },
	{ title: "Set Point 2", className: "time-zone", sort: true },
	{ title: "Return Air 2", className: "time-zone", sort: true },
	{ title: "Discharge Air 2", className: "time-zone", sort: true },
	{ title: "Set Point 3", className: "time-zone", sort: true },
	{ title: "Return Air 3", className: "time-zone", sort: true },
	{ title: "Discharge Air 3", className: "time-zone", sort: true },
	{ title: "Power", className: "time-zone", sort: true },
	{ title: "Sensor 1", className: "time-zone", sort: true },
	{ title: "Sensor 2", className: "time-zone", sort: true },
	{ title: "Sensor 3", className: "time-zone", sort: true },
	{ title: "Sensor 4", className: "time-zone", sort: true },
	{ title: "Sensor 5", className: "time-zone", sort: true },
	{ title: "Sensor 6", className: "time-zone", sort: true },
	{ title: "TouchLog Sensor 1", className: "time-zone", sort: true },
	{ title: "TouchLog Sensor 2", className: "time-zone", sort: true },
	{ title: "TouchLog Sensor 3", className: "time-zone", sort: true },
	{ title: "TouchLog Sensor 4", className: "time-zone", sort: true },
	{ title: "TouchLog Sensor 5", className: "time-zone", sort: true },
	{ title: "TouchLog Sensor 6", className: "time-zone", sort: true },
]

const jobStatusThemoKing = {
	jobNotStarted: "Job Not Started",
	jobInProgress: "Job In Progress",
	jobFinished: "Job Finished",
}


export {
	apiUrl,
	colors,
	routes,
	reportsHeading,
	requestHeading,
	invoiceHeading,
	consignMentData,
	addLooseWayData,
	addWayBillsData,
	awbByAwbNumberHeading,
	reportsHeadingULDLoose,
	dashboardListingHeader,
	jobListing,
	runSheetData,
	customerDetailAWBHeader,
	customerDetailConsignmentHeader,
	rowsPerPageVal,
	integrationApiPermissions,
	integrationTokenHeading,
	jobStatus,
	jobRunSheetStatus,
	sourceId,
	temperatureTrackingHeading,
	jobStatusThemoKing,
	dataJobStatus
};
