import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  Zoom,
  Tooltip as HtmlTooltip
} from "@material-ui/core";

import { useStore } from "@store/store";
import Loader from "@components/loader";
import {
  utcToLocal,
  convertMinutesToHours
} from "@utils/commonFunctions";
import { TableStyle } from "./style";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import infoIcon from "@assets/images/information-icon.svg";

function TableListing(props) {
  const classes = TableStyle();
  const materilClasses = materialCommonStyles();
  const [state] = useStore();

  return (
    <>
      <div className={classes.TableWrapper}>
        <Loader
          loading={
            state.job?.gettingJobRunsheet ||
            state.job?.downloadingCsvJobRunsheet ||
            state.job?.downloadingPdfJobRunsheet
          }
        />
        <TableContainer component={Paper} className={classes.customTable}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="status">Status</TableCell>
                <TableCell className="time">Time</TableCell>
                <TableCell className="location">Location</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!state?.job?.jobRunSheetData?.jobRunsheetReport?.length ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    Job Runsheet Report Not Found
                  </TableCell>
                </TableRow>
              ) : (
                state?.job?.jobRunSheetData?.jobRunsheetReport?.map(
                  (item, index) => (
                    <TableRow key={index}>
                      <TableCell className="status">
                        {item.jobRunsheetStatus ? item.jobRunsheetStatus : "-"}
                        {item?.connectionLost &&
                          (<HtmlTooltip
                            TransitionComponent={Zoom}
                            title={
                              <>
                                <p>
                                  Connection Lost. Please check again.
                                </p>
                              </>
                            }
                            interactive
                            arrow
                            placement="top"
                            classes={{ tooltip: materilClasses.modalTooltip }}
                            enterTouchDelay={1}
                          >
                            <span className="info-icon">
                              {" "}
                              <img src={infoIcon} alt="Info" />
                            </span>
                          </HtmlTooltip>)
                        }
                      </TableCell>
                      <TableCell className="time">
                        {utcToLocal(
                          item.time,
                          state?.job?.jobRunSheetData?.timezone,
                          "DD/MM/yyyy HH:mm:ss"
                        ) || "-"}
                      </TableCell>
                      <TableCell className="location">
                        {item.location ? item.location : "-"}
                      </TableCell>
                    </TableRow>
                  )
                )
              )}
            </TableBody>
            {state?.job?.jobRunSheetData?.jobRunsheetReport?.length && (
              <TableFooter>
                <TableRow>
                  {state?.job?.jobRunSheetData?.totalDuration && (
                    <TableCell>
                      Total time:{" "}
                      {state?.job?.jobRunSheetData?.totalDuration
                          ? convertMinutesToHours(
                              state?.job?.jobRunSheetData?.totalDuration
                          )
                          : "-"}
                    </TableCell>
                  )}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
export default TableListing;
