import React from "react";
import { NavLink } from "react-router-dom";

import profile from "@assets/images/profile.svg";
import profileRed from "@assets/images/profile-red.svg";
import profileWhite from "@assets/images/profile-white.svg";
import childAccount from "@assets/images/child-account.svg";
import childAccountRed from "@assets/images/child-account-red.svg";
import childAccountWhite from "@assets/images/child-account-white.svg";
import notification from "@assets/images/notification.svg";
import notificationRed from "@assets/images/notification-red.svg";
import notificationWhite from "@assets/images/notification-white.svg";
import timeZone from "@assets/images/timezone-icon.svg";
import timeZoneRed from "@assets/images/timezone-icon-red.svg";
import timeZoneWhite from "@assets/images/timezone-icon-white.svg";
// import consignmentNormal from "@assets/images/consignment-icon-normal.svg";
// import consignmentWhite from "@assets/images/consignment-icon-white.svg";
// import consignmentRed from "@assets/images/consignment-icon-red.svg";
// import awbNormal from "@assets/images/awb-icon-normal.svg";
// import awbNormalRed from "@assets/images/awb-icon-red.svg";
// import awbNormalWhite from "@assets/images/awb-icon-white.svg";
// import organisation from "@assets/images/organisation.svg";
// import organisationRed from "@assets/images/organisation-red.svg";
// import organisationWhite from "@assets/images/organisation-white.svg";
import { routes } from "@utils/constant";
import { profileSidebarStyle } from "./style";
import Logout from "../logout";
import { getUserData } from "@utils/commonFunctions";

function ProfileSideBar() {
  const classes = profileSidebarStyle();

  return (
    <div className={classes.profilesidebar}>
      <aside className="white-card">
        <ul>
          <li>
            <NavLink to={routes.myProfile} activeClassName="active">
              <em>
                <img src={profile} alt="Profile" className="normal-img" />
                <img src={profileRed} alt="Profile" className="hover-img" />
                <img src={profileWhite} alt="Profile" className="active-img" />
              </em>
              My profile
            </NavLink>
          </li>
          <li>
            <NavLink to={routes.childAccount} activeClassName="active">
              <em>
                <img
                  src={childAccount}
                  alt="Child Account"
                  className="normal-img"
                />
                <img
                  src={childAccountRed}
                  alt="Child Account"
                  className="hover-img"
                />
                <img
                  src={childAccountWhite}
                  alt="Child Account"
                  className="active-img"
                />
              </em>
              Child Accounts
            </NavLink>
          </li>

          <li>
            <NavLink to={routes.notifications} activeClassName="active">
              <em>
                <img
                  src={notification}
                  alt="Notifications "
                  className="normal-img"
                />
                <img
                  src={notificationRed}
                  alt="Notifications"
                  className="hover-img"
                />
                <img
                  src={notificationWhite}
                  alt="Notifications"
                  className="active-img"
                />
              </em>
              Notifications
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={routes.awb} activeClassName="active">
              <em>
                <img src={awbNormal} alt="awb " className="normal-img" />
                <img src={awbNormalRed} alt="awb" className="hover-img" />
                <img src={awbNormalWhite} alt="awb" className="active-img" />
              </em>
              AWB
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink to={routes.consignment} activeClassName="active">
              <em>
                <img
                  src={consignmentNormal}
                  alt="consignment "
                  className="normal-img"
                />
                <img
                  src={consignmentRed}
                  alt="consignment"
                  className="hover-img"
                />
                <img
                  src={consignmentWhite}
                  alt="consignment"
                  className="active-img"
                />
              </em>
              Consignment
            </NavLink>
          </li> */}
          <li>
              <NavLink to={routes.upload} activeClassName="active">
                <em>
                  <img
                    src={childAccount}
                    alt="organisation"
                    className="normal-img"
                  />
                  <img
                    src={childAccountRed}
                    alt="organisation"
                    className="hover-img"
                  />
                  <img
                    src={childAccountWhite}
                    alt="organisation"
                    className="active-img"
                  />
                </em>
                Upload
              </NavLink>
            </li>
            <li>
              <NavLink to={routes.integration} activeClassName="active">
                <em>
                  <img
                    src={childAccount}
                    alt="integration"
                    className="normal-img"
                  />
                  <img
                    src={childAccountRed}
                    alt="integration"
                    className="hover-img"
                  />
                  <img
                    src={childAccountWhite}
                    alt="integration"
                    className="active-img"
                />
              </em>
              Integration
            </NavLink>
          </li>

          {getUserData()?.connectedCustomer && getUserData()?.customerInfo?.length > 1 && (
          <li>
            <NavLink to={routes.timezone} activeClassName="active">
              <em>
                <img
                    src={timeZone}
                    alt="Timezone"
                    className="normal-img customize-img"
                />
                <img
                    src={timeZoneRed}
                    alt="Timezone"
                    className="hover-img customize-img"
                />
                <img
                    src={timeZoneWhite}
                    alt="Timezone"
                    className="active-img customize-img"
                />
              </em>
              Time Zone
            </NavLink>
          </li> )}
          <li className="logout-link">
            <Logout/>
          </li>
        </ul>
      </aside>
    </div>
  );
}

export default ProfileSideBar;
