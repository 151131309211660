import {
  LOADING_TIME_ZONE,
  UNLOADING_TIME_ZONE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loadingTimeZone: false,
};

const timeZoneReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING_TIME_ZONE:
      return { ...state, loadingTimeZone: true };
    case UNLOADING_TIME_ZONE:
      return { ...state, loadingTimeZone: false };
    default:
      return state;
  }
};

export default timeZoneReducer;
